/* src/css/VideoCarousel.css */

.video-carousel-container {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 10;  /* Ensure carousel is above other content */
  }
  
  .video-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.6);  /* Dim background */
    z-index: 5;  /* Ensure the overlay stays below the video */
  }
  
  .video-carousel {
    position: relative;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 10;  /* Ensure carousel is above overlay */
  }
  
  video {
    width: auto;         /* Scale the width to auto (maintain aspect ratio) */
    height: 100%;        /* Ensure video fits the height of the container */
    object-fit: contain; /* Ensure the entire video fits within the container */
    max-width: 100%;     /* Ensure the video does not exceed the width of the screen */
    max-height: 100%;    /* Ensure the video does not exceed the height of the screen */
    display: block;      /* Removes any extra space below the video */
    margin: auto;        /* Centers the video */
    z-index: 10;         /* Ensure the video is above the overlay */
  }
  
  .close-btn {
    position: absolute;
    top: 20px;
    right: 20px;
    font-size: 2rem;
    background-color: rgba(0, 0, 0, 0.5);
    color: white;
    padding: 10px;
    cursor: pointer;
    border: none;
    z-index: 15;  /* Ensure close button is above the video */
  }
  