/* src/components/lotr/Lotr.css */

.lotr {
  text-align: center; /* Centers the h1 text */
  background-image: url('/public/images/lord-of-the-rings-wallpaper-and-wallpaper-inspirations-image-o5bqpc28pe6gu51t.jpg'); /* Path to the JPEG file */
  background-size: cover;   /* Ensure the image covers the entire container */
  background-position: center center;  /* Center the background image */
  background-repeat: no-repeat;  /* Prevent repetition of the image */
  min-height: 100vh; /* Ensure the background covers the full height of the viewport */
  color: white; /* Optional: Change text color to white for better visibility */
  padding: 20px; /* Add some padding if needed */
}

.lotr h1 {
  font-family: 'Ringbearer', sans-serif; /* Apply the custom 'Aniron-7BaP' font */
  color: rgba(212, 159, 12, 1); /* Set the text color */
  font-size: 2.5rem; /* Adjust font size as needed */
  margin: 20px 0; /* Optional: Add some margin for spacing */
}

@font-face {
  font-family: 'Ringbearer'; /* Name the font */
  src: url('/public/css/fonts/Aniron-7BaP.ttf') format('truetype'); /* Path to the font */
  font-weight: normal;
  font-style: normal;
}