/* Container for all the buttons (Locations and Clips) */
.open-carousel-btn-container {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column; /* Stack buttons vertically for Locations and Clips */
  z-index: 100; /* Make sure the buttons are above other content */
  align-items: center;
}

/* Button styling for Locations and Clips section buttons */
.open-carousel-btn {
  padding: 15px 30px;
  font-size: 1.5rem;
  font-family: 'Ringbearer', sans-serif; /* Apply Elvish-style font */
  background-color: rgba(0, 0, 0, 0.7);
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  margin: 10px 0; /* Add margin to separate buttons vertically */
}

/* Hover effect for buttons */
.open-carousel-btn:hover, .clips-title:hover {
  background-color: rgba(0, 0, 0, 0.9);
}

/* Styling for Clips div, which behaves like a button */
.clips-btn-container {
  display: flex;
  flex-direction: column; /* Stack title and buttons vertically */
  align-items: center;
  justify-content: center;
  margin-top: 10px;
}

/* Clips title looks like a button but styled as text */
.clips-title {
  font-family: 'Ringbearer', sans-serif; /* Apply the Elvish-style font */
  font-size: 1.5rem;
  background-color: rgba(0, 0, 0, 0.7);
  padding: 15px 30px;
  width: 100%;
  text-align: center;
  cursor: pointer; /* Cursor as a pointer, like a button */
  
  border-radius: 5px;
}

/* When the clips title is clicked, show the buttons */
.clips-btn-container.show-buttons .clips-title {
  background-color: rgba(0, 0, 0, 0.8);
}

/* The two buttons (FOTR and TTT) inside the Clips section */
.clips-btn-container .clips-btns {
  display: flex;
  justify-content: center; /* Align buttons in a row */
  gap: 5px; /* Space between buttons */
  margin-top: 5px;
}

/* Styling for FOTR and TTT buttons */
.clips-btn-container .clips-btns button {
  width: 150px; /* Fixed width to make buttons uniform */
  padding: 10px;
  background-color: rgba(0, 0, 0, 0.7);
  color: white;
  font-size: 1.2rem;
  font-family: 'Ringbearer', sans-serif;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

/* Hover effect for buttons inside Clips */
.clips-btn-container .clips-btns button:hover {
  background-color: rgba(0, 0, 0, 0.9);
}

/* Ensuring the 'Locations' button is hidden when carousels are open */
.carousel-container.open .open-carousel-btn {
  display: none; /* Hide when carousel is open */
}

/* Carousel specific styles */
.carousel-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw; /* Full width of the viewport */
  height: 100vh; /* Full height of the viewport */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10; /* Ensure carousel and overlay are above the rest of the page */
  visibility: hidden; /* Initially hidden */
  opacity: 0;
  transition: opacity 0.3s ease, visibility 0s 0.3s; /* Fade in and out */
}

/* Show the carousel when it's open */
.carousel-container.open {
  visibility: visible; /* Show when open */
  opacity: 1;
  transition: opacity 0.3s ease;
}

/* Transparent overlay behind the carousel */
.carousel-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.2); /* Dimmed transparent black background */
  z-index: 5;
}

/* Making the carousel wider than the video */
.carousel {
  position: relative;
  width: 120vw; /* Wider than the viewport */
  max-height: 90vh; /* Keep a maximum height based on the viewport */
  height: 100vh; /* Full height of the viewport */
  overflow: hidden;
  background-color: rgba(0, 0, 0, 0.2); /* Semi-transparent dimmed background */
  z-index: 10;
  display: flex;
  justify-content: center; /* Ensure the content is centered */
  align-items: center; /* Vertically center content */
}

/* Video styles */
.carousel video {
  width: 100%; /* Make the video take up the full width of the carousel */
  height: auto; /* Maintain aspect ratio */
  max-height: 100%; /* Ensure the video doesn’t exceed the height of the carousel */
  object-fit: contain; /* Ensures the whole video is visible */
  opacity: 1; /* Show video immediately */
  transition: none; /* Disable fade-in transition */
  z-index: 1; /* Video stays below the controls */
}

/* Loading Placeholder */
.carousel-video-container.hidden {
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.5s ease-in-out;
}

/* Show the next video once it's ready */
.carousel-video-container.hidden {
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.3s ease-in-out, visibility 0.3s ease-in-out;
}

.carousel-video-container {
  opacity: 1;
  visibility: visible;
  transition: opacity 0.3s ease-in-out, visibility 0.3s ease-in-out;
}

/* Loader style */
.loading-placeholder {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5); /* Dim background */
  display: flex;
  justify-content: center;
  align-items: center;
}

.loader {
  color: white;
  font-size: 2rem;
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

/* Controls (Prev, Next, Close button) positioned outside video */
.carousel-control {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  font-size: 2rem;
  background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent background */
  color: white;
  padding: 10px;
  cursor: pointer;
  z-index: 15; /* Controls are above the video */
}

.prev {
  left: 5%; /* Position the prev button outside of the video */
}

.next {
  right: 5%; /* Position the next button outside of the video */
}

/* Close button */
.close-btn {
  position: absolute;
  top: 20px;
  right: 20px;
  font-size: 2rem;
  background-color: rgba(0, 0, 0, 0.5);
  color: white;
  padding: 10px;
  cursor: pointer;
  border: none;
  z-index: 20; /* Close button stays above everything */
}
